import { Component } from '@angular/core';
import { User } from '@api/model/models';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@shared/services/currentuser.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'flag-project';
  constructor(
    private translate: TranslateService,
    public currentUserService: CurrentUserService
  ) {
    translate.setDefaultLang('fr');

    const url = new URL(window.location.href);
    if (!url.pathname.includes('/reporting') && !url.pathname.includes('/resetPassword')) {
      this.currentUserService.currentUser$
      .subscribe((user: User) => {
        if (user.languageKey) {
          translate.use(user.languageKey.substring(0, 2));
        } else {
          translate.use(navigator.language.substring(0, 2));
        }
      });
    } else {
      translate.use(navigator.language.substring(0, 2));
    }

  }
}
