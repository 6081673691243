import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AboutPageService } from './api/aboutPage.service';
import { ActualitesService } from './api/actualites.service';
import { AgencesService } from './api/agences.service';
import { AgesService } from './api/ages.service';
import { AuteursService } from './api/auteurs.service';
import { CaracterisationService } from './api/caracterisation.service';
import { CategoriesActualitesService } from './api/categoriesActualites.service';
import { CommunesService } from './api/communes.service';
import { ContactService } from './api/contact.service';
import { CspService } from './api/csp.service';
import { DepartementsService } from './api/departements.service';
import { DepotSignalementService } from './api/depotSignalement.service';
import { EchelleCouleurService } from './api/echelleCouleur.service';
import { FileService } from './api/file.service';
import { GenreService } from './api/genre.service';
import { HistoriqueService } from './api/historique.service';
import { LangueService } from './api/langue.service';
import { LockService } from './api/lock.service';
import { NotificationsService } from './api/notifications.service';
import { OrganisationsService } from './api/organisations.service';
import { OrientationCritereService } from './api/orientationCritere.service';
import { OrientationPropositionService } from './api/orientationProposition.service';
import { OrientationSignalementService } from './api/orientationSignalement.service';
import { ParamService } from './api/param.service';
import { ParamMapService } from './api/paramMap.service';
import { PaysService } from './api/pays.service';
import { PermissionService } from './api/permission.service';
import { PersonnesService } from './api/personnes.service';
import { ProfilsService } from './api/profils.service';
import { SchemaTraductionService } from './api/schemaTraduction.service';
import { TraductionsService } from './api/traductions.service';
import { TypesAgentsService } from './api/typesAgents.service';
import { TypesSignalementsService } from './api/typesSignalements.service';
import { UserService } from './api/user.service';
import { VictimesService } from './api/victimes.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AboutPageService,
    ActualitesService,
    AgencesService,
    AgesService,
    AuteursService,
    CaracterisationService,
    CategoriesActualitesService,
    CommunesService,
    ContactService,
    CspService,
    DepartementsService,
    DepotSignalementService,
    EchelleCouleurService,
    FileService,
    GenreService,
    HistoriqueService,
    LangueService,
    LockService,
    NotificationsService,
    OrganisationsService,
    OrientationCritereService,
    OrientationPropositionService,
    OrientationSignalementService,
    ParamService,
    ParamMapService,
    PaysService,
    PermissionService,
    PersonnesService,
    ProfilsService,
    SchemaTraductionService,
    TraductionsService,
    TypesAgentsService,
    TypesSignalementsService,
    UserService,
    VictimesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
