import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserServiceProvider } from '@adista/window-kit-ui';
import { Observable, ReplaySubject, Subscription, of, combineLatest } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { UserService } from '@api/api/api';

import { OAuthService, OAuthData } from './oauth.service';
import { User } from '@api/model/user';
import { Permission } from '@api/model/models';

export function hasPermission(user: User, permission: string): boolean {
  return user.permissions.find((p: Permission) => p.slug === permission) !== undefined;
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService implements UserServiceProvider {
  private readonly _userSubject = new ReplaySubject<User>(1);
  private _user: User;
  private _userSubscription: Subscription;
  private _retrieving = false;

  get currentUser$(): Observable<User> {
    this.retrieveUser();
    return this._userSubject.asObservable();
  }

  constructor(
    private readonly userService: UserService,
    private readonly oAuthService: OAuthService,
    private readonly router: Router
  ) {
  }

  public isAuthenticated(): Observable<boolean> {
    return combineLatest([
      of(this.oAuthService.isLogged()),
      this.currentUser$.pipe(map((u: User) => u !== undefined))
    ])
      .pipe(map((result: [boolean, boolean]) => result[0] && result[1]));
  }

  public redirectToLogin(): void {
    this.router.navigate(['/login']);
  }

  public retrieveUser(): void {
    if (!this.oAuthService.isLogged()) {
      this.redirectToLogin();
      return;
    }

    if (this._user || this._retrieving) {
      return;
    }

    this._retrieving = true;
    this._userSubscription = this.userService.getCurrentUser()
      .subscribe(
        (user: User) => {
          this._userSubscription.unsubscribe();
          this._userSubscription = undefined;

          this._user = user;
          this._userSubject.next(this._user);
          this._retrieving = false;
        },
        () => this.logout()
      );
  }

  public login(credentials: any): Observable<OAuthData> {
    return this.oAuthService.fetchAccessToken(credentials)
      .pipe(tap(() => this.retrieveUser()));
  }

  public logout(): void {
    this._user = null;
    this.oAuthService.removeToken();
    this.redirectToLogin();
  }

  public can(permission: string): Observable<boolean> {
    return this.currentUser$
      .pipe(map((user: User) => hasPermission(user, permission)));
    // fix to make it work without permissions
    // return of(true);
  }

  public canSync(permission: string): boolean {
    return hasPermission(this._user, permission);
    // fix to make it work without permissions
    // return true;
  }
}
