import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PermissionDefiner } from '@shared/models/permission';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '@adista/window-kit-ui';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private readonly translateService: TranslateService) { }

  private translations = {
    fr: {
      administration: 'Administration',
      user: 'Utilisateurs',
      agent: 'Types d\'agents',
      victim: 'Types de victimes',
      people: 'Types de personnes',
      author: 'Types d\'auteurs',
      characterization: 'Catégorie de l\'acte',
      gender: 'Genres',
      age: 'Tranches d\'âge',
      csp: 'Catégories socioprofessionnelles',
      news: 'Actualités',
      partner: 'Partenaires',
      agency: 'Agences',
      report: 'Signalements',
      reporttype: 'Types d\'actes',
      job: 'Métiers',
      handiworktype: 'Types d\'ouvrages',
      interventiontype: 'Types d\'interventions',
      language: 'Langues',
      schema: 'Schéma de traduction',
      traductions: 'Traduction',
      associatedref: 'Références associées',
      handiworkcriterion: 'Aux types d\'ouvrages',
      configurationcriterion: 'Aux configurations',
      permissiontemplate: 'Modèles de permissions',
      pictogram: 'Pictogrammes',
      contact: 'Contacts',
      notification: 'Notifications',
      configuration: 'Configuration signalements',
      parameters: 'Paramètres des apps',
      parametersGlobal: 'Paramètres',
      parametersMap: 'Paramètres des cartes',
      country: 'Pays',
      translate: 'Traduction',
      departements: 'Départements',
      city: 'Villes',
      about: 'A propos',
      orientationproposal: 'Proposition d\'orientation',
      reportingProposal: 'Proposition de signalement',
      focusCartography: 'Localisation des signalements',
      profil: 'Mon profil',
      colorScale: 'Echelle de couleur',
      statistics: 'Statistiques',
      addReport: 'Ajout d’un signalement'
    },
    en: {
      administration: 'Administration',
      user: 'Users',
      agent: 'Types of agents',
      victim: 'Types of victims',
      people: 'Types of people',
      author: 'Types of authors',
      characterization: 'Category of the act',
      gender: 'Genders',
      age: 'Age range',
      csp: 'Socio-professional categories',
      news: 'News',
      partner: 'Partners',
      agency: 'Agencies',
      report: 'Reports',
      reporttype: 'Types of acts',
      job: 'Jobs',
      handiworktype: 'Handiworks types',
      interventiontype: 'Interventions types',
      language: 'Languages',
      schema: 'Schema translate',
      traductions: 'Traductions',
      associatedref: 'Associated references',
      handiworkcriterion: 'Handiworks criterions',
      configurationcriterion: 'Configurations criterions',
      permissiontemplate: 'Permissions templates',
      pictogram: 'Pictograms',
      contact: 'Contacts',
      notification: 'Notifications',
      configuration: 'Configuration of reports',
      parameters: 'Parameters of the apps',
      parametersGlobal: 'Parameters',
      parametersMap: 'Parameters of the maps',
      country: 'Country',
      translate: 'Translate',
      departements: 'Departments',
      city: 'Cities',
      about: 'About',
      orientationproposal: 'Proposal d\'orientation',
      reportingProposal: 'Proposal for reporting',
      focusCartography: 'Location of reports',
      profil: 'My profile',
      colorScale: 'Color Scale',
      addReport: 'Adding an report',
      statistics: 'Statistics',
    },
  };

  private readonly _allMenus: MenuItem[] = [
    {
      label: this.translations[this.translateService.currentLang].profil,
      permission: '',
      link: '/user/profile',
    },
    {
      label: this.translations[this.translateService.currentLang].report,
      permission: PermissionDefiner.REPORT.menu,
      link: '/depot-signalement/list',
    },
    {
      label: this.translations[this.translateService.currentLang].addReport,
      permission: PermissionDefiner.ADD_REPORT.menu,
      link: '/report/create',
    },
    {
      label: this.translations[this.translateService.currentLang].focusCartography,
      permission: PermissionDefiner.REPORT.menu,
      link: '/focus-cartographie/list',
    },
    {
      label: this.translations[this.translateService.currentLang].statistics,
      link: '/opener-splunk/page',
    },
    {
      label: this.translations[this.translateService.currentLang].news,
      permission: PermissionDefiner.NEWS.menu,
      link: '/actualites/list',
    },
    {
      label: this.translations[this.translateService.currentLang].parametersGlobal,
      subItems: [
        {
          label: this.translations[this.translateService.currentLang].parameters,
          permission: PermissionDefiner.PARAMETERS.menu,
          link: '/param/list',
        },
        {
          label: this.translations[this.translateService.currentLang].parametersMap,
          permission: PermissionDefiner.PARAMETERS.menu,
          link: '/param-map/list',
        },
      ]
    },
    {
      label: this.translations[this.translateService.currentLang].configuration,
      subItems: [
        {
          label: this.translations[this.translateService.currentLang].agent,
          permission: PermissionDefiner.TYPE_AGENT.menu,
          link: '/types-agents/list',
        },
        {
          label: this.translations[this.translateService.currentLang].victim,
          permission: PermissionDefiner.VICTIM.menu,
          link: '/victimes/list',
        },
        {
          label: this.translations[this.translateService.currentLang].people,
          permission: PermissionDefiner.PEOPLE.menu,
          link: '/personnes/list',
        },
        {
          label: this.translations[this.translateService.currentLang].author,
          permission: PermissionDefiner.AUTHOR.menu,
          link: '/auteurs/list',
        },
        {
          label: this.translations[this.translateService.currentLang].characterization,
          permission: PermissionDefiner.CHARACTERIZATION.menu,
          link: '/caracterisation/list',
        },
        {
          label: this.translations[this.translateService.currentLang].gender,
          permission: PermissionDefiner.GENDER.menu,
          link: '/genre/list',
        },
        {
          label: this.translations[this.translateService.currentLang].age,
          permission: PermissionDefiner.AGE.menu,
          link: '/ages/list',
        },
        {
          label: this.translations[this.translateService.currentLang].csp,
          permission: PermissionDefiner.CSP.menu,
          link: '/csp/list',
        },
        {
          label: this.translations[this.translateService.currentLang].country,
          permission: PermissionDefiner.COUNTRY.menu,
          link: '/pays/list',
        },
        {
          label: this.translations[this.translateService.currentLang].departements,
          permission: PermissionDefiner.DEPARTEMENTS.menu,
          link: '/departements/list',
        },
        {
          label: this.translations[this.translateService.currentLang].city,
          permission: PermissionDefiner.CITY.menu,
          link: '/communes/list',
        },
        {
          label: this.translations[this.translateService.currentLang].reporttype,
          permission: PermissionDefiner.REPORT_TYPE.menu,
          link: '/types-signalements/list',
        },
        {
          label: this.translations[this.translateService.currentLang].colorScale,
          permission: PermissionDefiner.COLOR_SCALE.menu,
          link: '/echelle-couleur/list',
        },
        {
          label: 'Orientation',
          subItems: [
            {
              label: this.translations[this.translateService.currentLang].orientationproposal,
              permission: PermissionDefiner.ORIENTATION_PROPOSAL.menu,
              link: '/orientation-proposition/list',
            },
            {
              label: this.translations[this.translateService.currentLang].reportingProposal,
              permission: PermissionDefiner.REPORTING_PROPOSAL.menu,
              link: '/orientation-signalement/list',
            },
          ],
        }
      ]
    },
    {
      label: this.translations[this.translateService.currentLang].user,
      permission: PermissionDefiner.USER.menu,
      link: '/user/list',
    },

    {
      label: this.translations[this.translateService.currentLang].partner,
      subItems: [
        {
          label: this.translations[this.translateService.currentLang].partner,
          permission: PermissionDefiner.ORGANIZATION.menu,
          link: '/organisations/list',
        },
        {
          label: this.translations[this.translateService.currentLang].agency,
          permission: PermissionDefiner.ORGANIZATION.menu,
          link: '/agences/list',
        },
      ],
    },
    {
      label: this.translations[this.translateService.currentLang].notification,
      permission: PermissionDefiner.NOTIFICATION.menu,
      link: '/notifications/list',
    },
    {
      label: this.translations[this.translateService.currentLang].contact,
      permission: PermissionDefiner.CONTACT.menu,
      link: '/contact/list',
    },
    {
      label: this.translations[this.translateService.currentLang].about,
      permission: PermissionDefiner.ABOUT.menu,
      link: '/about-page/list',
    },
    {
      label: this.translations[this.translateService.currentLang].translate,
      subItems: [
        {
          label: this.translations[this.translateService.currentLang].language,
          permission: PermissionDefiner.TRANSLATE.menu,
          link: '/langue/list',
        },
        {
          label: this.translations[this.translateService.currentLang].schema,
          permission: PermissionDefiner.SCHEMA_TRANSLATION.menu,
          link: '/schema/list',
        },
        {
          label: this.translations[this.translateService.currentLang].traductions,
          permission: PermissionDefiner.TRANSLATE.menu,
          link: '/traductions/list',
        },
      ]
    },
  ];

  public getMenus(): Observable<MenuItem[]> {
    return of(this._allMenus);
  }
}
