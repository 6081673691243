import { Component, OnInit } from '@angular/core';
import { KitService } from '@adista/window-kit-ui';

import { UserService } from '@api/api/api';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor(
    private readonly userService: UserService,
    private kit: KitService,

  ) { }

  ngOnInit() {

  }

}
