import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DynamicRouterModule } from '@adista/window-kit-ui';

import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./pages/styleguide/styleguide.module').then(m => m.StyleguideModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('./pages/reporting/reporting.module').then(m => m.ReportingModule)
  },
  {
    path: '**',
    component: NotFoundPageComponent
  }
];

const dynamicRoutes: Routes = [
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module')
      .then(m => m.UserModule)
  },
  {
    path: 'param',
    loadChildren: () => import('./modules/param/param.module')
      .then(m => m.ParamModule)
  },
  {
    path: 'types-agents',
    loadChildren: () => import('./modules/types-agents/types-agents.module')
      .then(m => m.TypesAgentsModule)
  },
  {
    path: 'victimes',
    loadChildren: () => import('./modules/victimes/victimes.module')
      .then(m => m.VictimesModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/contact/contact.module')
      .then(m => m.ContactModule)
  },
  {
    path: 'personnes',
    loadChildren: () => import('./modules/personnes/personnes.module')
      .then(m => m.PersonnesModule)
  },
  {
    path: 'auteurs',
    loadChildren: () => import('./modules/auteurs/auteurs.module')
      .then(m => m.AuteursModule)
  },
  {
    path: 'caracterisation',
    loadChildren: () => import('./modules/caracterisation/caracterisation.module')
      .then(m => m.CaracterisationModule)
  },
  {
    path: 'genre',
    loadChildren: () => import('./modules/genre/genre.module')
      .then(m => m.GenreModule)
  },
  {
    path: 'ages',
    loadChildren: () => import('./modules/ages/ages.module')
      .then(m => m.AgesModule)
  },
  {
    path: 'pays',
    loadChildren: () => import('./modules/pays/pays.module')
      .then(m => m.PaysModule)
  },
  {
    path: 'departements',
    loadChildren: () => import('./modules/departements/departements.module')
      .then(m => m.DepartementsModule)
  },
  {
    path: 'csp',
    loadChildren: () => import('./modules/csp/csp.module')
      .then(m => m.CspModule)
  },
  {
    path: 'actualites',
    loadChildren: () => import('./modules/actualites/actualites.module')
      .then(m => m.OrganisationsModule)
  },
  {
    path: 'organisations',
    loadChildren: () => import('./modules/organisations/organisations.module')
      .then(m => m.OrganisationsModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module')
      .then(m => m.NotificationsModule)
  },
  {
    path: 'types-signalements',
    loadChildren: () => import('./modules/types-signalements/types-signalements.module')
      .then(m => m.TypesSignalementsModule)
  },
  {
    path: 'about-page',
    loadChildren: () => import('./modules/about-page/about-page.module')
      .then(m => m.AboutPageModule)
  },
  {
    path: 'orientation-proposition',
    loadChildren: () => import('./modules/orientation-proposition/orientation-proposition.module')
      .then(m => m.OrientationPropositionModule)
  },
  {
    path: 'orientation-signalement',
    loadChildren: () => import('./modules/orientation-signalement/orientation-signalement.module')
      .then(m => m.OrientationSignalementModule)
  },
  {
    path: 'depot-signalement',
    loadChildren: () => import('./modules/depot-signalement/depot-signalement.module')
      .then(m => m.DepotSignalementModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./modules/report/report.module')
      .then(m => m.ReportModule)
  },
  {
    path: 'langue',
    loadChildren: () => import('./modules/langue/langue.module')
      .then(m => m.LangueModule)
  },
  {
    path: 'schema',
    loadChildren: () => import('./modules/schema-traduction/schema-traduction.module')
      .then(m => m.SchemaTraductionModule)
  },
  {
    path: 'traductions',
    loadChildren: () => import('./modules/traductions/traductions.module')
      .then(m => m.TraductionsModule)
  },
  {
    path: 'focus-cartographie',
    loadChildren: () => import('./modules/focus-cartographie/focus-cartographie.module')
      .then(m => m.FocusCartographieModule)
  },
  {
    path: 'opener-splunk',
    loadChildren: () => import('./modules/opener-splunk/opener-splunk.module')
      .then(m => m.OpenerSplunkModule)
  },
  {
    path: 'communes',
    loadChildren: () => import('./modules/communes/communes.module')
      .then(m => m.CommunesModule)
  },
  {
    path: 'agences',
    loadChildren: () => import('./modules/agences/agences.module')
      .then(m => m.AgencesModule)
  },
  {
    path: 'echelle-couleur',
    loadChildren: () => import('./modules/echelle-couleur/echelle-couleur.module')
      .then(m => m.EchelleCouleurModule)
  },
  {
    path: 'param-map',
    loadChildren: () => import('./modules/param-map/param-map.module')
      .then(m => m.ParamMapModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    DynamicRouterModule.forRoot(dynamicRoutes)
  ],
  exports: [
    RouterModule,
    DynamicRouterModule
  ]
})
export class AppRoutingModule { }
