export const environment = {
  production: false,
  //apiAccessPoint: 'https://flag-api.flag-prod-1.kumba.adista.fr',
  apiAccessPoint: '/api',
  amqAccessPoint: '/wss',
  API_CREDENTIALS: {
    clientId: '1_2h4fus52xm68ss00koo80gc8gwkgk0og8wkkg8ws4kww0k4c0s',
    clientSecret: '1vxe2882uydcss0sw80g880sg0cokokgkgswcgccckwwcc4kg0'
  }
};
