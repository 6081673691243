import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgModule } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import {
  WINDOW_MANAGER_CONFIG_PROVIDER,
  KIT_UI_CONFIG_PROVIDER,
  KitUIModule,
  KITUI_RESOURCES_PROVIDER
} from '@adista/window-kit-ui';
import { OsmModule } from '@adista/osm';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {
  NgxMatDatetimeContent,
  NgxMatTimepickerModule,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule
} from '@angular-material-components/datetime-picker';

import { MaterialComponentsModule } from './material-components.module';

import { BASE_PATH, ApiModule, LockService } from '@api/index';

import { configurationProvider } from '@shared/helpers/configuration.provider';
import { CurrentUserService } from '@shared/services/currentuser.service';
import { ErrorMessageService } from '@shared/services/error-message.service';
import { MenuService } from '@shared/services/menu.service';
import { ShortcutService } from '@shared/services/shortcut.service';
import { TranslateKitService } from '@shared/services/translate-kit.service';
import { TopbarComponent } from '@components/topbar/topbar.component';

import { environment } from 'src/environments/environment';
import { flagWMConfig, flagUIConfig } from './kit.config';
import { rxStompConfig } from './rx-stomp.config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';
import { CustomTranslateLoader } from '@shared/services/custom-translate-loader.service';
import { TraductionsService } from '@api/api/api';

export function getApiBasePath(): string {
  return environment.apiAccessPoint;
}

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    TopbarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    MaterialComponentsModule,
    HttpClientModule,
    KitUIModule,
    ApiModule,
    OsmModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, TraductionsService, CurrentUserService]
      }
    })
  ],
  entryComponents: [
    AppComponent,
    TopbarComponent,
    NgxMatDatetimeContent
  ],
  providers: [
    configurationProvider,
    DatePipe,

    // Locale Material
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR'
    },

    // OpenAPI
    {
      provide: BASE_PATH,
      useFactory: getApiBasePath
    },

    // Stomp
    {
      provide: InjectableRxStompConfig,
      useValue: rxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    },

    // Kit UI
    {
      provide: WINDOW_MANAGER_CONFIG_PROVIDER,
      useFactory: flagWMConfig,
      deps: [
        RxStompService,
        CurrentUserService,
        ErrorMessageService,
        LockService
      ]
    },
    {
      provide: KIT_UI_CONFIG_PROVIDER,
      useFactory: flagUIConfig,
      deps: [
        MenuService,
        ShortcutService,
      ]
    },

    // Resources
    {
      provide: KITUI_RESOURCES_PROVIDER,
      useClass: TranslateKitService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient, traductionsService: TraductionsService, currentUserService: CurrentUserService) {

  return new CustomTranslateLoader(traductionsService,
    currentUserService);
}
