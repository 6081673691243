
export class PermissionAccess {
  public readonly create: string;
  public readonly read: string;
  public readonly edit: string;
  public readonly delete: string;
  public readonly menu: string;
  public readonly admin: string;

  constructor(public readonly name: string) {
    this.create = `${this.name}:create`;
    this.read = `${this.name}:read`;
    this.edit = `${this.name}:edit`;
    this.delete = `${this.name}:delete`;
    this.menu = `${this.name}:menu`;
    this.admin = `${this.name}:admin`;
  }
}

export class PermissionDefiner {
  public static readonly REPORT = new PermissionAccess('report');
  public static readonly NEWS = new PermissionAccess('news');
  public static readonly PARAMETERS = new PermissionAccess('parameters');
  public static readonly TYPE_AGENT = new PermissionAccess('agent');
  public static readonly VICTIM = new PermissionAccess('victim');
  public static readonly PEOPLE = new PermissionAccess('people');
  public static readonly AUTHOR = new PermissionAccess('author');
  public static readonly CHARACTERIZATION = new PermissionAccess('characterization');
  public static readonly GENDER = new PermissionAccess('gender');
  public static readonly AGE = new PermissionAccess('age');
  public static readonly CSP = new PermissionAccess('csp');
  public static readonly COUNTRY = new PermissionAccess('country');
  public static readonly DEPARTEMENTS = new PermissionAccess('departements');
  public static readonly CITY = new PermissionAccess('city');
  public static readonly REPORT_TYPE = new PermissionAccess('report-type');
  public static readonly ORIENTATION_PROPOSAL = new PermissionAccess('orientation-proposal');
  public static readonly REPORTING_PROPOSAL = new PermissionAccess('reporting-proposal');
  public static readonly USER = new PermissionAccess('user');
  public static readonly ORGANIZATION = new PermissionAccess('organization');
  public static readonly INTERVENTION = new PermissionAccess('intervention');
  public static readonly NOTIFICATION = new PermissionAccess('notification');
  public static readonly CONTACT = new PermissionAccess('contact');
  public static readonly ABOUT = new PermissionAccess('about');
  public static readonly TRANSLATE = new PermissionAccess('translate');
  public static readonly SCHEMA_TRANSLATION = new PermissionAccess('schema-translation');
  public static readonly COLOR_SCALE = new PermissionAccess('color-scale');
  public static readonly ADD_REPORT = new PermissionAccess('add-report');
}
