import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor(
      private readonly translateService: TranslateService
  ) {
  }

  private readonly _messagesFR: Map<string, string> = new Map([
    ['required', 'Ce champ est obligatoire.'],
    ['max', 'La valeur est trop grande.'],
    ['min', 'La valeur est trop petite.'],
    ['pattern', 'Format invalide.'],
    ['email', 'Une adresse email au format exemple@exemple.com est attendue.'],
    ['minLength', 'La taille de la chaine est trop courte.'],
    ['maxLength', 'La taille de la chaine est trop grande.']
  ]);

  private readonly _messagesEN: Map<string, string> = new Map([
    ['required', 'This field is required.'],
    ['max', 'The value is too large.'],
    ['min', 'The value is too small.'],
    ['pattern', 'Invalid format.'],
    ['email', 'An email address in the format example@example.com is expected.'],
    ['minLength', 'The chain size is too short.'],
    ['maxLength', 'The chain size is too large.']
  ]);

  public getMessage(errors: ValidationErrors): string {
    if (errors === null || Object.keys(errors)[0] === null) {
      return '';
    }

    switch (this.translateService.currentLang) {
        case 'fr': {
            return this._messagesFR.get(Object.keys(errors)[0]);
        }
        case 'en': {
            return this._messagesEN.get(Object.keys(errors)[0]);
        }
        default: {
            return this._messagesFR.get(Object.keys(errors)[0]);
        }
    }
  }
}
