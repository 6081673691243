import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { TraductionsService } from '@api/api/api';
import { CurrentUserService } from './currentuser.service';
import { User } from '@api/model/models';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

  private readonly _userSubject = new ReplaySubject<User>(1);
  constructor(
    public traductionsService: TraductionsService,
    public currentUserService: CurrentUserService
  ) {
  }

  public getTranslation(lang: string): Observable<any> {
    const subject = new Subject<string>();
    subject.subscribe((data) => {
      return of(data);
    });

    const url = new URL(window.location.href);
      if (!url.pathname.includes('/reporting') && !url.pathname.includes('/resetPassword')) {
      this.currentUserService.currentUser$
        .subscribe((user: User) => {
          if (user.languageKey) {
            lang = user.languageKey.substring(0, 2);
            this.traductionsService.generateTranslation(lang)
              .subscribe((trad: any) => {
                subject.next(trad);
              });
          } else {
            this.traductionsService.generateTranslation(lang)
              .subscribe((trad: any) => {
                subject.next(trad);
              });
          }
        });
    } else {
      this.traductionsService.generateTranslation(lang)
        .subscribe((trad: any) => {
          subject.next(trad);
        });
    }

    return subject.asObservable();
  }

}
