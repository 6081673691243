import {
  WindowManagerConfig,
  UserServiceProvider,
  ErrorMessageServiceProvider,
  MenuServiceProvider,
  ShortcutServiceProvider,
  KitUIConfig,
  StompService,
} from '@adista/window-kit-ui';
import { ApiLockService } from '@adista/common';

import { TopbarComponent } from '@components/topbar/topbar.component';

export function flagWMConfig(
  stompService: StompService,
  userService: UserServiceProvider,
  errorMessageService: ErrorMessageServiceProvider,
  lockService: ApiLockService
): WindowManagerConfig {
  return {
    services: {
      userService,
      errorMessageService
    },
    entities: {
      lock: {
        lockService,
        listen: true
      },
      messaging: {
        stompService,
        baseDestination: '/topic/Flag',
        listen: true
      }
    }
  };
}

export function flagUIConfig(
  menuService: MenuServiceProvider,
  shortcutService: ShortcutServiceProvider
): KitUIConfig {
  return {
    multipleDesktops: false,
    controlClickOpen: false,

    menu: {
      visible: true,
      menuService
    },

    /*sideNav: {
      visible: true,
      hasDisconnect: true,
      shortcutService
    },*/

    topBar: {
      visible: true,
      hasDisconnect: true,
      barTemplate: TopbarComponent
    }
  };
}
