import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIResourcesProvider, UIText, UITextMap } from '@adista/window-kit-ui';

@Injectable()
export class TranslateKitService implements UIResourcesProvider {

  constructor(
    private readonly translateService: TranslateService
  ) {
  }

  // Ensemble des chaines utilisées dans le kit.
  private readonly _textsFr: UITextMap = {
    [UIText.EntityCreated]: 'Élément créé',
    [UIText.EntityUpdated]: 'Élément mis à jour',
    [UIText.EntityDeleted]: 'Élément supprimé',
    [UIText.EntityDeleteError]: 'Impossible de supprimer cet élément',

    [UIText.NotifyInfo]: 'Information',
    [UIText.NotifyError]: 'Erreur',

    [UIText.WindowNew]: 'Nouveau',
    [UIText.WindowEdit]: 'Éditer',
    [UIText.WindowCancel]: 'Annuler',
    [UIText.WindowSave]: 'Enregistrer',

    [UIText.WindowMenuPrint]: 'Imprimer',
    [UIText.WindowMenuDelete]: 'Supprimer',
    [UIText.WindowMenuDuplicate]: 'Dupliquer',
    [UIText.WindowMenuConfig]: 'Configurer',

    [UIText.LockTitle]: 'Verrouillage',
    [UIText.LockOk]: 'OK',
    [UIText.LockForce]: 'Force',
    [UIText.LockCancel]: 'Annuler',
    [UIText.LockLockedMessage]: 'Cet élément a été verrouillé le {0} par {1}',
    [UIText.LockForcedMessage]: 'L\'édition de cet élément a été forcée par {0}, vos modifications vont être annulées.',

    [UIText.DesktopTitle]: 'Mes bureaux',
    [UIText.DesktopDelete]: 'Supprimer',
    [UIText.DesktopAdd]: 'Ajouter un bureau',
    [UIText.DesktopAddTab]: 'Ajouter un bureau dans un nouveau navigateur',

    [UIText.WindowPanelsTitle]: 'Mes fenêtres ouvertes',
    [UIText.WindowPanelsEmpty]: 'Vous n\'avez aucune fenêtre ouverte...',

    [UIText.NavDisconnect]: 'Déconnexion'
  };

  private readonly _textsEn: UITextMap = {
    [UIText.EntityCreated]: 'Element created',
    [UIText.EntityUpdated]: 'Element updated',
    [UIText.EntityDeleted]: 'Element deleted',
    [UIText.EntityDeleteError]: 'Unable to delete this element',

    [UIText.NotifyInfo]: 'Information',
    [UIText.NotifyError]: 'Error',

    [UIText.WindowNew]: 'New',
    [UIText.WindowEdit]: 'Edit',
    [UIText.WindowCancel]: 'Cancel',
    [UIText.WindowSave]: 'Save',

    [UIText.WindowMenuPrint]: 'Print',
    [UIText.WindowMenuDelete]: 'Delete',
    [UIText.WindowMenuDuplicate]: 'Duplicate',
    [UIText.WindowMenuConfig]: 'Configure',

    [UIText.LockTitle]: 'Locking',
    [UIText.LockOk]: 'OK',
    [UIText.LockForce]: 'Force',
    [UIText.LockCancel]: 'Cancel',
    [UIText.LockLockedMessage]: 'This element was locked on {0} by {1}',
    [UIText.LockForcedMessage]: 'This element was forced for edition on {0}, your changes will won\'t be saved.',

    [UIText.DesktopTitle]: 'My desks',
    [UIText.DesktopDelete]: 'Delete',
    [UIText.DesktopAdd]: 'Add a desk',
    [UIText.DesktopAddTab]: 'Add a desk in a new browser',

    [UIText.WindowPanelsTitle]: 'My open windows',
    [UIText.WindowPanelsEmpty]: 'You have no windows open ...',

    [UIText.NavDisconnect]: 'Sign Out'
  };

  public get texts(): UITextMap {
    switch (this.translateService.currentLang) {
      case 'fr': {
        return this._textsFr;
      }
      case 'en': {
        return this._textsEn;
      }
      default: {
        return this._textsFr;
      }
    }
  }

  public translateProp(holder, key, prop): void {
    this.translateService.get(key)
      .subscribe((res: string) => {
          holder[prop] = res;
        }
      );
  }

  public translateInstant(key): any {
    return this.translateService.instant(key);
  }
}
