/**
 * Flag WS
 * Flag Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AboutPage } from '../model/aboutPage';
import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { ListAboutPagesResponse } from '../model/listAboutPagesResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AboutPageService {

    protected basePath = 'http://api.flag.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Supprime une page à propos
     * Suppression d&#39;une page à propos
     * @param id Identifiant de la page à propos à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(id: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling _delete.');
        }

        let headers = this.defaultHeaders;

        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/about_page/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer une page à propos
     * Permet de créer une page à propos
     * @param aboutPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(aboutPage: AboutPage, observe?: 'body', reportProgress?: boolean): Observable<AboutPage>;
    public create(aboutPage: AboutPage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AboutPage>>;
    public create(aboutPage: AboutPage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AboutPage>>;
    public create(aboutPage: AboutPage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (aboutPage === null || aboutPage === undefined) {
            throw new Error('Required parameter aboutPage was null or undefined when calling create.');
        }

        let headers = this.defaultHeaders;

        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AboutPage>(`${this.configuration.basePath}/about_page`,
            aboutPage,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une page à propos
     * Avec l&#39;identifiant (id) retourne une page à propos
     * @param id Identifiant (id) de la page à propos à retourner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean): Observable<AboutPage>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AboutPage>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AboutPage>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AboutPage>(`${this.configuration.basePath}/about_page/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de page à propos
     * Retourne la liste de toutes les pages à propos
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListAboutPagesResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAboutPagesResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAboutPagesResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling list.');
        }

        let headers = this.defaultHeaders;

        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListAboutPagesResponse>(`${this.configuration.basePath}/about_pages`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une page à propos
     * Permet de mettre à jour une page à propos
     * @param id Identifiant de la page à propos à mettre à jour
     * @param aboutPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(id: number, aboutPage: AboutPage, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(id: number, aboutPage: AboutPage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(id: number, aboutPage: AboutPage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(id: number, aboutPage: AboutPage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling update.');
        }
        if (aboutPage === null || aboutPage === undefined) {
            throw new Error('Required parameter aboutPage was null or undefined when calling update.');
        }

        let headers = this.defaultHeaders;

        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/about_page/${encodeURIComponent(String(id))}`,
            aboutPage,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
