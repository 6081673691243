import { Injectable, Inject, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, first, filter } from 'rxjs/operators';
import { LocalStorageService, KitService } from '@adista/window-kit-ui';

import { environment } from 'src/environments/environment';

export interface OAuthData {
  access_token: string;
  grant_type?: string;
  expires_in: number;
  refresh_token: string;
  expiration_date?: number;
}

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  protected clientId = null;
  protected clientSecret = null;
  protected url = null;

  protected readonly lsTokenKey = 'oauthtoken';
  protected readonly lsUserKey = 'userData';
  protected readonly formDataHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  constructor(
    protected http: HttpClient,
    public lsService: LocalStorageService,
    private readonly router: Router,
    private readonly injector: Injector
  ) {
    this.url = environment.apiAccessPoint;
    this.clientId = environment.API_CREDENTIALS.clientId;
    this.clientSecret = environment.API_CREDENTIALS.clientSecret;
  }

  public fetchAccessToken(credentials: any): Observable<OAuthData> {
    credentials.client_id = this.clientId;
    credentials.client_secret = this.clientSecret;
    credentials.grant_type = 'password';

    const formData: string = this.getFormData(credentials);

    return this.http
      .post(this.url + '/oauth/v2/token', formData, { headers: this.formDataHeaders })
      .pipe(tap((res: OAuthData) => this.setToken(res)));
  }

  public getToken(): string | null {
    if (this.isTokenExpired(0)) {
      this.removeToken();
      return null;
    }

    if (this.isTokenExpired(60 * 20)) {
      // Si le token est presque expiré, recupère le nouveau token en background
      this.refreshToken();
    }

    const oauthData: OAuthData = this.getOAuthData();
    return oauthData ? oauthData.access_token : null;
  }

  protected refreshToken() {
    const oauthToken: OAuthData = this.getOAuthData();
    const credentials = {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      grant_type: 'refresh_token',
      refresh_token: oauthToken.refresh_token,
    };
    const formData: string = this.getFormData(credentials);

    return this.http
      .post(this.url + '/oauth/v2/token', formData, { headers: this.formDataHeaders })
      .subscribe(data => this.setToken(data as OAuthData));
  }

  public setToken(token: OAuthData): void {
    token.expiration_date = Math.floor(Date.now() / 1000) + token.expires_in;
    this.lsService.set(this.lsTokenKey, token);
  }

  public isLogged(): boolean {
    return this.getOAuthData() !== null && !this.isTokenExpired();
  }

  public removeToken(): void {
    this.lsService.remove(this.lsTokenKey);
    this.lsService.remove(this.lsUserKey);

    const kit: KitService = this.injector.get(KitService);

    kit.desktop.closeWorkspace()
      .pipe(first(), filter((result: boolean) => result))
      .subscribe(() => this.router.navigate(['/login']));
  }

  public isTokenExpired(delay: number = 0): boolean {
    if (this.getOAuthData() === undefined) {
      return true;
    }
    const oauthData: OAuthData = this.getOAuthData();
    return (oauthData.expiration_date - delay) < (Date.now() / 1000);
  }

  protected getOAuthData(): OAuthData | null {
    const token: OAuthData = this.lsService.get(this.lsTokenKey);
    return token || null;
  }

  protected getExpirationDate(): number {
    const token: OAuthData = this.getOAuthData();
    if (!token) {
      throw new Error('No token stored');
    }
    return token.expiration_date;
  }

  protected getFormData(object: object): string {
    const formData: string[] = [];
    Object.keys(object).forEach(key =>
      formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(object[key]))
    );
    return formData.join('&');
  }

}
