import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ShortcutItem, ShortcutSearch } from '@adista/window-kit-ui';

@Injectable({
  providedIn: 'root'
})
export class ShortcutService {
  private readonly _allShortcuts: ShortcutItem[] = [
    { label: 'SCENARIO', icon: 'create', link: '/scenario/form' },
    { label: 'SCN LIST', icon: 'list', link: '/scenario/list' }
  ];

  private readonly _allSearches: ShortcutSearch[] = [];

  public getShortcuts(): Observable<ShortcutItem[]> {
    return of(this._allShortcuts);
  }

  public getSearches(): Observable<ShortcutSearch[]> {
    return of(this._allSearches);
  }
}
